<!-- eslint-disable vue/no-v-html -->
<template>
  <b-card-code title="" no-body>
    <b-card-body>
      <h3>Total Rekomendasi Izin Praktik : {{ rows }}</h3>
    </b-card-body>

    <b-card-body>
      <b-row>
        <b-col md="2" xl="2" class="mb-1">
          <!-- kategori -->
          <b-form-group
            label="Status Rekomendasi"
            label-for="status_rekomendasi_izin_praktik"
          >
            <validation-provider
              #default="{ errors }"
              name="status_rekomendasi_izin_praktik"
            >
              <v-select
                v-model="filter.reksip_kategori"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusKategoriRekom"
                placeholder="pilih kategori SIP..."
              />
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="2" xl="2" class="mb-1">
          <!-- status admin -->
          <b-form-group label="Name Dokter" label-for="nama_dokter">
            <validation-provider #default="{ errors }" name="nama_dokter">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="nama_dokter"
                  placeholder="search name dokter..."
                  v-model="filter.orang_nama_lengkap"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="2" xl="2" class="mb-1">
          <!-- status admin -->
          <b-form-group label="Kompetensi Nomor" label-for="kompetensi_nomor">
            <validation-provider #default="{ errors }" name="kompetensi_nomor">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="komptensi_nomor"
                  placeholder="search name dokter..."
                  v-model="filter.reksip_kompetensi_no"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="2" xl="2" class="mb-1">
          <!-- status admin -->
          <b-form-group label="Alamat Instansi" label-for="alamat_instansi">
            <validation-provider #default="{ errors }" name="alamat_instansi">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="alamat_instansi"
                  placeholder="search name dokter..."
                  v-model="filter.reksip_alamat_instansi"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="2" xl="2" class="mb-1">
          <!-- status admin -->
          <b-form-group
            label="Tanggal Daftar"
            label-for="tanggal_daftar_reksip"
          >
            <validation-provider
              #default="{ errors }"
              name="tanggal_daftar_reksip"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="tanggal_daftar_reksip"
                  placeholder="search name dokter..."
                  v-model="filter.created_at"
                  type="date"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col lg="2" md="2" class="mb-50">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-0 mt-md-2"
            @click="refreshPage()"
          >
            <feather-icon icon="SearchIcon" class="mr-25" />
            <span>Search</span>
          </b-button>
        </b-col>
      </b-row>

      <b-button-toolbar
        class="d-flex justify-content-between flex-wrap pt-0"
        aria-label="Toolbar with button groups and dropdown menu"
      >
        <!-- group -->
        <b-button-group>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="reloadPage"
          >
            <feather-icon icon="RefreshCwIcon" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </b-card-body>

    <!-- table -->
    <b-table
      ref="selectableTable"
      selectable
      :select-mode="selectMode"
      :items="items"
      :fields="fields"
      responsive
      class="mb-0"
      @row-selected="onRowSelected"
    >
      <template #cell(action)="data">
        <b-dropdown variant="link" no-caret toggle-class="p-0" right>
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>

          <b-dropdown-item @click="modalDetailReskip(data.item)">
            <feather-icon icon="SettingsIcon" size="16" />
            <span class="align-middle ml-50">Detail</span>
          </b-dropdown-item>
          <b-dropdown-item @click="modalUndanganKrip(data.item)">
            <feather-icon icon="CalendarIcon" size="16" />
            <span class="align-middle ml-50">Buat Undangan KRIP</span>
          </b-dropdown-item>
          <b-dropdown-item @click="modalTerbitkan(data.item)">
            <feather-icon icon="ExternalLinkIcon" size="16" />
            <span class="align-middle ml-50">Terbitkan</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <div></div>
      <!--pagination -->
      <div class="demo-inline-spacing pt-0">
        <b-pagination
          v-model="currentPage"
          hide-goto-end-buttons
          :total-rows="rows"
          :per-page="perPage"
        />
      </div>
    </b-card-body>
    <b-modal id="modal-export" hide-footer>
      <template #modal-title> Input Email Tujuan </template>
      <div class="d-block text-center">
        <p>
          Data yang akan diexport lebih dari <b>500</b> baris dan akan dikirim
          via email, Silahkan masukkan email anda!
        </p>
      </div>
      <b-form-group>
        <label for="email">Email:</label>
        <b-form-input
          id="email"
          type="email"
          placeholder="Email Address"
          v-model="email"
        />
      </b-form-group>
      <b-button
        class="mt-3"
        variant="outline-primary"
        block
        @click="exportData()"
      >
        <feather-icon icon="DownloadCloudIcon" /> Export
      </b-button>
    </b-modal>

    <!-- rekomendasi izin praktik-->
    <b-modal id="modal-detail-reskip" hide-footer>
      <template #modal-title> Detail Rekomendasi Izin Praktik </template>
      <!-- Invoice Description: Total -->
      <table style="width: 100%">
        <tr>
          <td><b>Status :</b></td>
        </tr>
        <b-badge variant="success" v-if="detailReskip.reksip_terbit != false"
          >Sudah Terbit</b-badge
        >
        <b-badge variant="danger" v-else>Belum Terbit</b-badge>
        <tr>
          <td><b>Invoice :</b></td>
        </tr>
        <p
          v-if="
            detailReskip.invoice_id != null && detailReskip.invoice_id.id != 0
          "
        >
          <a :href="`/invoice/${detailReskip.invoice_id.id}`" target="_blank">{{
            detailReskip.invoice_id.id
          }}</a>
        </p>
        <p v-else>belum ada</p>
        <tr>
          <td><b>Pendidikan :</b></td>
        </tr>
        <p v-if="detailReskip.reksip_pend_file != null">
          <a
            v-if="!detailReskip.reksip_pend_file.includes('https')"
            :href="
              'https://www.staging.idijakpus.or.id/uploads/reksip/reksip_pend_file/' +
                detailReskip.id +
                '/' +
                detailReskip.reksip_pend_file
            "
            target="_blank"
          >
            tampilkan PDF</a
          >
          <a v-else :href="detailReskip.reksip_pend_file" target="_blank"
            >tampilkan PDF</a
          >
        </p>
        <p v-else>belum ada</p>
        <tr>
          <td><b>STR :</b></td>
        </tr>
        <p v-if="detailReskip.reksip_str_file != null">
          <a
            v-if="!detailReskip.reksip_str_file.includes('https')"
            :href="
              'https://www.staging.idijakpus.or.id/uploads/reksip/reksip_str_file/' +
                detailReskip.id +
                '/' +
                detailReskip.reksip_str_file
            "
            target="_blank"
          >
            tampilkan PDF</a
          >
          <a v-else :href="detailReskip.reksip_str_file" target="_blank"
            >tampilkan PDF</a
          >
        </p>
        <p v-else>belum ada</p>
        <tr>
          <td><b>Krip :</b></td>
        </tr>
        <p v-if="detailReskip.reksip_krip_file != null">
          <a
            v-if="!detailReskip.reksip_krip_file.includes('https')"
            :href="
              'https://www.staging.idijakpus.or.id/uploads/reksip/reksip_krip_file/' +
                detailReskip.id +
                '/' +
                detailReskip.reksip_krip_file
            "
            target="_blank"
          >
            tampilkan PDF</a
          >
          <a v-else :href="detailReskip.reksip_krip_file" target="_blank"
            >tampilkan PDF</a
          >
        </p>
        <p v-else>belum ada</p>
        <tr>
          <td><b>Surat Keterangan kerja :</b></td>
        </tr>
        <p v-if="detailReskip.reksip_file_keterangan_kerja != n">
          <a
            v-if="!detailReskip.reksip_file_keterangan_kerja.includes('https')"
            :href="
              'https://www.staging.idijakpus.or.id/uploads/reksip/reksip_file_keterangan_kerja/' +
                detailReskip.id +
                '/' +
                detailReskip.reksip_file_keterangan_kerja
            "
            target="_blank"
          >
            tampilkan PDF</a
          >
          <a
            v-else
            :href="detailReskip.reksip_file_keterangan_kerja"
            target="_blank"
            >tampilkan PDF</a
          >
        </p>
        <p v-else>belum ada</p>
        <tr>
          <td><b>Surat Keterangan Perhimpunan :</b></td>
        </tr>
        <p v-if="detailReskip.reksip_file_keterangan_perhimpunan != null">
          {{ detailReskip.reksip_file_keterangan_perhimpunan }}
        </p>
        <p v-else>belum ada</p>
        <tr>
          <td>
            <b
              >Surat Rekomendasi Cabang (bagi non anggota IDI cabang Jakarta
              Pusat) :</b
            >
          </td>
        </tr>
        <p v-if="detailReskip.reksip_file_rekomcabang_untuk_nonjakpus != null">
          <a
            v-if="
              !detailReskip.reksip_file_rekomcabang_untuk_nonjakpus.includes(
                'https'
              )
            "
            :href="
              'https://www.staging.idijakpus.or.id/uploads/reksip/reksip_file_rekomcabang_untuk_nonjakpus//' +
                detailReskip.id +
                '/' +
                detailReskip.reksip_file_rekomcabang_untuk_nonjakpus
            "
            target="_blank"
          >
            tampilkan PDF</a
          >
          <a
            v-else
            :href="detailReskip.reksip_file_rekomcabang_untuk_nonjakpus"
            target="_blank"
            >tampilkan PDF</a
          >
        </p>
        <p v-else>belum ada</p>
        <tr>
          <td>
            <b>File Surat SIP yang telah sah yang di terbitkan :</b>
          </td>
        </tr>
        <p v-if="detailReskip.reksip_sah_ketua_idi_cab != null">
          <a
            v-if="!detailReskip.reksip_sah_ketua_idi_cab.includes('https')"
            :href="
              'https://www.staging.idijakpus.or.id/uploads/reksip/reksip_sah_ketua_idi_cab/' +
                detailReskip.id +
                '/' +
                detailReskip.reksip_sah_ketua_idi_cab
            "
            target="_blank"
          >
            tampilkan PDF</a
          >
          <a
            v-else
            :href="detailReskip.reksip_sah_ketua_idi_cab"
            target="_blank"
            >tampilkan File</a
          >
        </p>
        <p v-else>belum ada</p>
        <tr>
          <td><b>Surat Keterangan dari IDI Wilayah :</b></td>
        </tr>
        <p v-if="detailReskip.reksip_file_surat_idi_wilayah != null">
          {{ detailReskip.reksip_file_surat_idi_wilayah }}
        </p>
        <p v-else>belum ada</p>
        <tr>
          <td><b>Pernyataan tidak kena sanksi: </b></td>
        </tr>
        <p v-if="detailReskip.reksip_tidak_kena_sanksi != null">
          {{ detailReskip.reksip_tidak_kena_sanksi }}
        </p>
        <p v-else>belum ada</p>
      </table>
    </b-modal>

    <b-modal
      id="modal-terbitkan-sip"
      title="Terbitkan SIP"
      cancel-variant="outline-secondary"
      hide-footer
    >
      <validation-observer ref="validateBeforeTerbitkanSIP">
        <b-form @submit.prevent>
          <b-form-group label="File Sah SIP" label-for="file_sah_sip ">
            <validation-provider
              #default="{ errors }"
              name="File Sah SIP"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-file
                  id="file_sah_sip"
                  multiple
                  v-model="uploadSipSah"
                  :state="errors.length > 0 ? false : null"
                  :file-name-formatter="formatNames"
                  @change="handlerUploadSipSah($event)"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <br />
          <b-button
            variant="primary"
            type="submit"
            block
            :disabled="invalid"
            @click="validateBeforeTerbitkanSIP"
          >
            Terbitkan/uploadFile
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>

    <!-- add undangan krip-->
    <b-modal
      id="undangan-krip"
      title="Jadwal Krip"
      cancel-variant="outline-secondary"
      hide-footer
    >
      <b-form @submit.prevent>
        <b-form-input id="id" v-model="undanganKrip.id" hidden />
        <br />
        <b-form-group>
          <v-select
            v-model="kripjadwal_tanggal"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="tanggal"
            placeholder="pilih tanggal..."
            :options="option"
          />
        </b-form-group>
        <br />
        <b-button
          variant="primary"
          type="submit"
          block
          :disabled="invalid"
          @click="buatUndanganKrip"
        >
          Submit
        </b-button>
      </b-form>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import {
  BTable,
  BButton,
  BFormGroup,
  BAvatar,
  BBadge,
  BCardBody,
  BPagination,
  BFormTextarea,
  BFormFile,
  BFormSelect,
  BButtonToolbar,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BRow,
  BCol,
  BModal,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BCardText,
  BFormTimepicker,
  BFormSpinbutton,
} from "bootstrap-vue";
import API from "@/api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCardCode,
    BTable,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
    BPagination,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BFormFile,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BRow,
    BFormSelect,
    BCol,
    BModal,
    BFormTextarea,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BCardText,
    BFormTimepicker,
    BFormSpinbutton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      currentPage: 1,
      rows: 0,
      perPage: 20,
      // lihat jadwal
      jadwalCurrentPage: 1,
      jadwalRows: 0,
      jadwalPerPage: 20,
      fields: [
        { key: "id", label: "Id" },
        [{ key: "orang_id.orang_nama_lengkap", label: "Nama" }],
        [{ key: "reksip_kategori_detail", label: "kategori" }],
        [{ key: "reksip_nama_instansi", label: "Instansi Praktik" }],
        [{ key: "reksip_kompetensi_jenis", label: "Kompetensi Jenis" }],
        [{ key: "reksip_kompetensi_no", label: "Kompetensi Nomor" }],
        [{ key: "reksip_alamat_instansi", label: "Alamat Instansi" }],
        [{ key: "created_at", label: "Waktu daftar" }],
        "action",
      ],
      items: [],
      search: null,
      detailReskip: {
        invoice_id: {
          id: "",
        },
      },
      undanganKrip: {},
      formatted: "",
      tanggals: [],

      selected: {
        tgl: "",
      },
      option: [],
      statusKategoriRekom: ["umum", "spesialis"],
      filter: {
        reksip_kategori: null,
        orang_nama_lengkap: null,
        reksip_alamat_instansi: null,
        reksip_kompetensi_no: null,
        created_at: null,
      },
      uploadSipSah: "",
      terbitkanRekom: "", //menyimpan data rekom yang siap untuk di terbikan
    };
  },
  methods: {
    validateBeforeTerbitkanSIP() {
      this.$refs.validateBeforeTerbitkanSIP.validate().then((success) => {
        if (success) {
          this.terbitkanRskip();
        }
      });
    },
    handlerUploadSipSah(e) {
      const { files } = e.target;
      if (files.length) {
        this.createHandlerUploadSipSah(files[0], (result) => {
          this.uploadSipSah = result;
        });
      }
    },
    createHandlerUploadSipSah(file, cb) {
      const reader = new FileReader();
      reader.onload = (e) => {
        cb(e.target.result);
      };
      reader.readAsDataURL(file);
    },
    /* eslint-disable */
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    refreshPage() {
      if (this.currentPage == 1) {
        this.getRekomendasiIzinPraktik();
        this.tanggal_krip();
      } else {
        this.currentPage = 1;
      }
    },
    formatNames(files) {
      if (files.length === 1) {
        return files[0].name;
      }
      return `${files.length} files selected`;
    },

    modalDetailReskip(item) {
      this.detailReskip = item;
      this.$bvModal.show("modal-detail-reskip");
    },
    modalUndanganKrip(item) {
      this.undanganKrip = item;
      this.tanggal_krip();
      this.loopTanggal();
      this.$bvModal.show("undangan-krip");
    },
    reloadPage() {
      location.reload();
    },

    modalTerbitkan(item) {
      this.detailSIP(item);
      this.$bvModal.show("modal-terbitkan-sip");
    },

    async getRekomendasiIzinPraktik() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        const form = {
          limit: this.perPage,
          page: this.currentPage,
          search: this.search,
          ...this.filter,
        };
        const { data } = await API.rekomendasi_izin_praktik.list(form);
        this.data_table = data;
        this.items = data.data;
        this.rows = data.total;

        this.handleMsgSuccess(
          "Berhasil tampilkan data Rekomendasi Izin Praktik"
        );
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async detailSIP(ids) {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
        var id = ids.id; // get id for update rekom
        const { data } = await API.rekomendasi_izin_praktik.detail(id);
        this.terbitkanRekom = data; //triger datanya buat bisa di jalankan ke fungsi terbitkan rekom
        this.handleMsgSuccess("Berhasil load data rekom");
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "gagal terbitkan");
      } finally {
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },
    async terbitkanRskip() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
        var id = this.terbitkanRekom.id;
        var data = { reksip_sah_ketua_idi_cab: this.uploadSipSah }; // upload file sah sip untuk sip yang telah terbit
        await API.rekomendasi_izin_praktik.terbitkan(id, data);
        this.handleMsgSuccess("Berhasil terbikan rekomendasi izin praktik");
        this.$bvModal.hide("modal-terbitkan-sip");
        location.reload();
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "gagal terbitkan");
      } finally {
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },
    async loopTanggal() {
      await this.tanggals.map((tanggals) =>
        this.option.push(tanggals.kripjadwal_tanggal)
      );
    },
    async tanggal_krip() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
        const form = {
          limit: this.jadwalPerPage,
          page: this.jadwalCurrentPage,
          search: this.search,
        };
        const { data } = await API.jadwal_krip.list(form);
        this.tanggals = data.data;
        this.handleMsgSuccess("berhasil menampilkan jadwal krip");
      } catch (error) {
        this.handleMsgErrors(error, "error sistem");
      } finally {
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },
    async buatUndanganKrip() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        var id = this.undanganKrip.id;
        let body = {
          kripjadwal_id: this.kripjadwal_tanggal,
        };
        const { data } = await API.rekomendasi_izin_praktik.undangan(id, body);
        this.handleMsgSuccess("Berhasil tambah undangan Krip");
        this.refreshPage();
      } catch (error) {
        this.handleMsgErrors(error, "gagal add undangan krip");
      } finally {
        this.$bvModal.hide("undangan-krip");
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },
  },
  mounted() {
    this.getRekomendasiIzinPraktik();
    this.tanggal_krip();
  },
  watch: {
    currentPage() {
      this.getRekomendasiIzinPraktik();
    },
  },
};
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
